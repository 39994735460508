import React from 'react'
import { Modal, Box, Typography, IconButton, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

function TargetedDrugDelivery({ title, content, closeModal }) {
  return (
    <Modal open={true} onClose={closeModal} >
      <Box
      className='portfolio-items'
        sx={{
          position: 'absolute',
          width: '60%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)', 
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          border:'1px solid white',
          borderRadius:'10px',
        //  backgroundColor: 'rgba(255,255,255, 0.5)', // Slightly transparent white
        //  backdropFilter: 'blur(20px)' // Apply a blur effect
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeModal}
          aria-label="close"
          sx={{ position: 'absolute', top: 8, right: 15 }}
        >
            <CloseIcon/>
            
        </IconButton>
        <Typography variant="h3" className="section-title" mb={2}>
          {title}
        </Typography>
        <Divider sx={{width:'100%', y:2}} className='linearBack'   />
        <Typography sx={{my:3,color:'#333333' }}>
            <h4>Problem/Opportunity</h4>
            <p> Targeted drug delivery aims to deliver medications precisely to the intended site, improving efficacy and reducing side effects. By delivering medications directly to the intended site of action within the body, this approach enhances the drug's effectiveness in treating the specific disease or condition while minimizing its impact on healthy tissues. As a result, patients experience reduced side effects and improved overall treatment tolerability. Targeted drug delivery also contributes to better treatment outcomes, potentially leading to faster recovery and improved quality of life. Additionally, this personalized medicine approach allows for tailored treatments based on individuals. This approach expands therapeutic possibilities, making once-toxic drugs viable. Challenges include complex development, target identification, and regulatory approval. Despite these, targeted drug delivery holds promise for revolutionizing medicine through precision treatment and better patient care.
            </p>
            <h4>
            Solution
            </h4>
            <p>
            Using micrno-algae as the carrier of the drug, a number of challenges described above can be averted. More specifically, micro-algae are abundant, readily available and non-toxic making them a safe, cheap solution that can be employed en masse. The founder of TrikkaLabs has been involved in research projects (ValueMag - H2020, BIONAMA GSRT) which have already demonstrated the viability of introducing magnetic nanoparticles inside micro-algae cells thus making them susceptible to magnetic fields. Because of their small size they are suitable for navigation within the body's vascular system and they can be loaded with a variety of pharmacuticals and reach most vital organs. TrikkaLabs was founded to commercialize the idea of using those cells for targeted drug delivery.
            </p> 
        </Typography>
      </Box>
    </Modal>
  )
}

export default TargetedDrugDelivery
