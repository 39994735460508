import React, { useState } from "react"; 
import TargetedDrugDelivery from "./Modals/TargetedDrugDelivery";
import TestModal from "./Modals/TestModal";
import { Image } from "./image";
import IntersectionObserverComponent from "./scripts/IntersectionObserverComponent";

const modalComponents = {
  TargetedDrugDelivery: TargetedDrugDelivery, 
  // Add other modal components here
  
  //  TestModal: TestModal
};

export const Research = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedResearch, setSelectedResearch] = useState({
    title: "",
    modalName: "" // Add the modalName property
  });

  const openModal = (title, modalName) => {
    setSelectedResearch({ title, modalName });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  }; 

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <IntersectionObserverComponent animationClass="slideLeft">
          <div className="section-title">
            <h2>Research</h2>
            <p>Some of the projects we are currently working on</p>
          </div>
        </IntersectionObserverComponent>
        <div className="row">
          <div className="portfolio-items" >
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4" >
                    <Image
                      title={d.title}
                      smallImage={d.smallImage}
                      onClick={() => openModal(d.title, d.modalName)} // Pass the modalName
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>

      {modalOpen && selectedResearch.modalName && modalComponents[selectedResearch.modalName] && (
          React.createElement(
            modalComponents[selectedResearch.modalName],
            {
              title: selectedResearch.title,
              closeModal: closeModal
            }
          )
        )}

    </div>
  );
};
