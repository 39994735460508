import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import IntersectionObserverComponent from "./scripts/IntersectionObserverComponent";
import LinearProgress from '@mui/material/LinearProgress';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PlaceIcon from '@mui/icons-material/Place';

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {

  const [{ name, email, message }, setState] = useState(initialState);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [inProgress, setInProgress] = useState(false)



  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });
      
  const REACT_APP_SERVICE = process.env.REACT_APP_SERVICE;
  const REACT_APP_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const REACT_APP_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  const handleSubmit = (e) => {
    e.preventDefault(); 
    setInProgress(true)
    emailjs
      .sendForm(REACT_APP_SERVICE, REACT_APP_TEMPLATE_ID, e.target, REACT_APP_PUBLIC_KEY)
      .then(
        (result) => {
          // console.log(result.text);
          setIsMessageSent(true); // Set the success status
          setInProgress(false)
          clearState();
        },
        (error) => {
          // console.log(error.text);
        }
      );
  };


  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-7">
            <div className="row">
              <div className="section-title">
              <IntersectionObserverComponent animationClass="slideRight">
                <h2>Get In Touch</h2>
                </IntersectionObserverComponent>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        disabled={isMessageSent}
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                      disabled={isMessageSent}
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                  disabled={isMessageSent}
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <div id="success"> 
                </div>
                {inProgress &&    <LinearProgress color="secondary" /> } 
                {isMessageSent && <h1 className="slideUp"> Message sent successfully! </h1>}
              
                <button type="submit" className="btn btn-custom btn-lg" disabled={isMessageSent}>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-4 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3076.3490658152505!2d21.76517487642041!3d39.55173610783047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13591ed6a8c9a3ef%3A0x5808ecf7fe1dd4ed!2zzprOsc-Azr_OtM65z4PPhM-Bzq_Ov8-FIDI0LCDOpM-Bzq_Ous6xzrvOsSA0MjEgMDA!5e0!3m2!1sel!2sgr!4v1692798133805!5m2!1sel!2sgr" width="550" height="220"   allowfullscreen="" title='map' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 
            </div>
              <p>
                <span>
                <PlaceIcon sx={{fontSize:18, marginBottom:-0.2}} />  Address <br/>
                </span>
                {props.data ? 
                  <a style={{textDecoration:'none', color:'white'}}  href='https://goo.gl/maps/Ymae6uDuVNWZ2vTZ9' target='blank'>
                       <b>  { props.data.address} </b> 
                  </a>
                : "loading"}
                
              </p>
             
            <div className="contact-item">
              <p>
                <span>
                  <LocalPhoneIcon sx={{fontSize:20}} /> Phone
                </span>{" "}
               {props.data ? <a style={{textDecoration:'none', color:'white'}}  href={props.data.phone} >    <b> {props.data.phone } </b> </a>  : "loading"}
    
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                <EmailIcon sx={{fontSize:18, marginBottom:-0.2}} /> Email
                </span>{" "}
                <p>  <b>  info   <AlternateEmailIcon sx={{fontSize:15, marginBottom:-0.3,marginRight:-0.35, marginLeft:-0.35}} /> trikkalabs.com  </b> </p>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                     <FacebookIcon  sx={{color:'white', fontSize:40}}  />
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.LinkedIn : "/"}>
                    <LinkedInIcon  sx={{color:'white', fontSize:40}}  />
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                    <YouTubeIcon  sx={{color:'white', fontSize:40}}  />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 TrikkaLabs P.C.
          </p>
        </div>
      </div>
    </div>
  );
};
