import React from "react";
import IntersectionObserverComponent from "./scripts/IntersectionObserverComponent";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>

                <img src="img/logo.png" style={{width:'350px'}} alt="" />
              
                  <span></span>
                </h1>
                <p>Founded to bring innovative, eco-friendly engineering solutions <br/> to biotech and healthcare.</p>
                
                <IntersectionObserverComponent animationClass={'slideDown'}>
                  <a href="#about" className="btn btn-custom btn-lg page-scroll" >
                    Learn More About Us
                  </a>  
                </IntersectionObserverComponent> 
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
